<template>
  <div>
    <div class="login">
    <h1>Login</h1>
      <p>
        <input type="text" id="username" placeholder="Username" v-model="username" />
      </p>
      <p>
        <input type="password" id="password" placeholder="Password" v-model="password"/>
      </p>
      <div>
        <input type="checkbox" id="savepassword" name="savepassword"><label for="savepassword">Sauvegarder les identifiants ?</label>
      </div>     
      <button @click="handleSubmit">Submit</button>
    </div>
    <div class="version">
      Version 1.2.1
      <div id="backversion">
      </div>
    </div>
  </div>
  
  
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  
  const config = require("../config");
  const axios = require('axios');
  const store = useStore();
  

  const username = ref('');
  const password = ref('');
  
  onMounted(async () =>
  {
    const url = `${config.apiUrl}` + '/api/version/'
    await axios
        .get(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }).then((response) => {
            backversion.innerHTML = response.data.geoDataBase + ' - ' + response.data.backend
        }).catch((error) => {
            console.log(error);
        });
        username.value = localStorage.getItem('id');
        password.value = localStorage.getItem('pswd');
  })

  function handleSubmit()
  {
    store.dispatch("tryToLog", { 
      username : username.value, 
      password : password.value, 
    });

    if (savepassword.checked == true)
    {
      localStorage.setItem('id', username.value);
      localStorage.setItem('pswd', password.value);
    }
  }
</script>
  
<style lang="css">
  .login {
    width: 400px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
    margin-top: 100px;
    border-radius: 20px;
  }

  input {
    border: none;
    outline: none;
    border-bottom: 1px solid #ddd;
    font-size: 1em;
    padding: 5px 0;
    margin: 10px;
    width: 100%;
  }

  button {
    background-color: #34bddb;
    color: white;
    padding: 10px 20px;
    border: none;
    margin-top: 10px;
  }

  .version {
    margin: 20px;
  }
</style>
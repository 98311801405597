import { errorWebSocket } from "./wsUtils";

let timer = null;
let WATCHDOG_SEC = 10 * 1000;

export function startWatchdog()
{
    if (timer == null)
    {
        timer = setTimeout(errorWebSocket, WATCHDOG_SEC);
    }
}

export function resetWatchdog()
{
    if (timer != null)
    {
        clearTimeout(timer);
        timer = null;
    }
    startWatchdog();
}
<template>
  <div class="map">
    <div id="textBox">
      <div id="textBoxText">test test</div>
      <div id="changeMapGroupIn">
        <button id="legende0In" @click="changeDataBinOn(0)">0%</button>
        <button id="legende50In" @click="changeDataBinOn(50)">50%</button>
        <button id="legende100In" @click="changeDataBinOn(100)">100%</button>
      </div>
      <button id="annulerButton" @click="reset()">Annuler</button>
    </div>
    <div id="infoBox" type="text">
      <h1 id="titleInfoBox" type="text"></h1>
      <h2>Calibre</h2>
      <select id="calibreInfoSelect" @change="onCalibreChange()">
        <option v-for="selectValue in calibrePossibles" :value="div>div>selectValue" v-bind:key="selectValue.id">{{ selectValue }}</option>
      </select>
      <h2 id="Occupation">Occupation : {{actual_occupation}}</h2>
      <div id="btn-group">
        <button id="btn0" @click="changeActualOccupation(0)">0</button>
        <button id="btn25" @click="changeActualOccupation(25)">25</button>
        <button id="btn50" @click="changeActualOccupation(50)">50</button>
        <button id="btn75" @click="changeActualOccupation(75)">75</button>
        <button id="btn100" @click="changeActualOccupation(100)">100</button>
      </div>
      <button id="validButton" @click="validClick()">Fermer</button>
    </div>
    <div id="productionBox" type="text">
        <h1>Production</h1>
        <select id="productionCalibreInfoSelect" @change="onProductionCalibreChange()">
          <option v-for="selectValue in calibrePossibles" :value="selectValue" v-bind:key="selectValue.id">{{ selectValue }}</option>
        </select>
        <button id="productionValidButton" @click="productionValidClick()">Fermer</button>
    </div>
    <div id="scierieBox" type="text">
        <h1>Scierie</h1>
        <select id="scierieCalibreInfoSelect" @change="onScierieCalibreChange()">
          <option v-for="selectValue in calibrePossibles" :value="selectValue" v-bind:key="selectValue.id">{{ selectValue }}</option>
        </select>
        <button id="scierieValidButton" @click="scierieValidClick()">Fermer</button>
    </div>
    <div id="changeMapGroup">
      <button id="changeMapButton" @click="changeMap()">Voir Occupation</button>
      <button id="legende0">0%</button>
      <button id="legende25">25%</button>
      <button id="legende50">50%</button>
      <button id="legende75">75%</button>
      <button id="legende100">100%</button>
    </div>
    <div id="changeScierieView">
      <button id="changeScierieViewButton" @click="changeScierie()">Voir Compatible Scierie</button>
    </div>
    <MapContainer @affTextBox="affTextBox" ref="mapContainer"/>
    <div id="posInfos">
      Ici les infos de positions
    </div>
  </div>
</template>

<script setup>

import MapContainer from '../components/MapContainer.vue';
import { Status } from '@/utils/wsUtils';
import store from '@/store/';
import { watch } from 'vue';
import { getJSON, postJSON } from '@/api/functionsApi';
import { onMounted, ref } from 'vue';
import { resetState, changeDataForActualBin } from '@/utils/stateUtils'
import { drawOccupation, redrawMap, drawScierieView, initOlder } from '@/utils/mapUtils';

var calibrePossibles = [];
var old_nom;
var old_calibre;
var old_occupation;
var actual_occupation = 0;

const mapContainer = ref();

let calibreHaveChange = false;

function reset()
{
  console.log("RESET STATE");
  resetState();
}

function changeActualOccupation(new_value)
{
  calibreHaveChange = true;
  actual_occupation = new_value;
  Occupation.innerHTML = 'Occupation : ' + actual_occupation;
  modifyButton();
}

function changeDataBinOn(value)
{
  changeDataForActualBin(value);
}
function modifyButton()
{
  const new_calibre = calibreInfoSelect.value;
  const new_occupation = actual_occupation;
  var haveChange = false;

  if (old_calibre == undefined) {
    if (new_calibre != '')
    {
      haveChange = true;
    }
  } else {
    if (new_calibre != old_calibre)
    {
      haveChange = true;
    }
  }
  
  if (new_occupation != old_occupation)
  {
    haveChange = true;
  }

  if (haveChange == true)
  {
    validButton.innerHTML= 'Valider';
  } else {
    validButton.innerHTML ='Fermer';
  }
}

function onCalibreChange()
{
  modifyButton();
}

onMounted(async () =>
{
  calibrePossibles = await getJSON('/api/getAllCalibre');
  validButton.onclick = validClick;
  scierieValidButton.onclick = scierieValidClick();
  productionValidButton.onclick = productionValidClick();
})

function scierieValidClick()
{
  scierieBox.style.visibility = 'hidden';
  const payload = { 'nom' : '', 'calibre' : '', 'occupation' : '' };
  store.commit('SET_INFO_SELECTED', payload);
  if (scierieCalibreInfoSelect.value != '')
  {
    const params = { 'calibre' : scierieCalibreInfoSelect.value };
    postJSON('/api/updateScierie', params);
  }
}

function productionValidClick()
{
  productionBox.style.visibility = 'hidden';
  const payload = { 'nom' : '', 'calibre' : '', 'occupation' : '' };
  store.commit('SET_INFO_SELECTED', payload);
  if (productionCalibreInfoSelect.value != '')
  {
    const params = {'longueur_mm' : parseInt(productionCalibreInfoSelect.value)};
    postJSON('/api/updateLongueur', params);
  }
}

async function validClick()
{
  //const old_data = store.getters.getInfoSelected;
  const new_calibre = calibreInfoSelect.value;
  const new_occupation = actual_occupation;
  var haveChange = false;

  if (old_calibre == undefined) {
    if (new_calibre != '')
    {
      haveChange = true;
    }
  } else {
    if (new_calibre != old_calibre)
    {
      haveChange = true;
    }
  }
  
  if (new_occupation != old_occupation)
  {
    if (new_occupation != 0 || (new_occupation ==0 && calibreHaveChange == true))
      haveChange = true;
  }
  const payload = { 'nom' : '', 'calibre' : '', 'occupation' : '' };
  store.commit('SET_INFO_SELECTED', payload);
  if (haveChange == true)
  {
    const params = {'occupation' : parseInt(new_occupation), 'calibre' : new_calibre, 'zone' : old_nom};
    postJSON('/api/updateStock', params);
  }
  calibreHaveChange = false;
}

watch(() => store.getters.getInfoIsSelected, () => {
  affInfoBox(store.getters.getInfoIsSelected);
})

watch(() => store.getters.getAffPosChange, () => {
  updatePosBox();
})

watch(() => store.getters.getPosInfosChange, () => {
  updatePosInfos();
})

function updatePosInfos()
{
  const data = store.getters.getPosInfos;
  const code = zeroPad(data.code, 100);

  posInfos.innerHTML = 'x = ' + data.x + ' y = ' + data.y + ' pos = ' + data.pos + ' code = ' + code;
  if (data.button == true)
  {
    posInfos.color = '#00ff00';
  } else {
    posInfos.color = 'ffffff';
  }
}

function updatePosBox()
{
  const data = store.getters.getAffPos;

  const visibility = data.visibility;
  const color = data.color;
  const border = data.border;
  const text = data.text;
  const buttonVisibility = data.buttonVisibility;
  const inVisibility = data.inVisibility;
  textBox.style.visibility = visibility;
  textBox.style.border = border;
  textBox.style.backgroundColor = color;
  annulerButton.style.backgroundColor = color;
  textBoxText.innerHTML = text;
  changeMapGroup.style.visibility =  buttonVisibility;
  changeScierieViewButton.style.visibility = buttonVisibility;
  changeMapGroupIn.style.display = inVisibility;
}

function affTextBox({ status, actualBin, calibrePick })
{
  switch (status)
  {
    case Status.NONE:
    {
      textBox.style.visibility = 'hidden';
      changeMapButton.style.visibility = 'visible';
    }
    break;
    case Status.ON_BIN :
    {
      textBox.style.visibility = 'visible';
      textBox.style.border = '7px solid red';
      textBox.style.backgroundColor = '#ff6767';
      annulerButton.style.backgroundColor = '#ff6767';
      textBoxText.innerHTML = 'Vous êtes proches de ' + actualBin + ', appuyer sur le bouton confirmer';
      changeMapButton.style.visibility = 'hidden';
    }
    break;
    case Status.TARGET_SELECTION :
    { if (calibrePick != undefined && calibrePick != '')
      {
        textBox.style.visibility = 'visible';
        textBox.style.border = '7px solid yellow';
        textBox.style.backgroundColor = '#ffff93';
        annulerButton.style.backgroundColor = '#ffff93';
        textBoxText.innerHTML = 'Vous avez pris du ' + calibrePick + ', selectionnez la cible';
        changeMapButton.style.visibility = 'hidden';
      }
    }
    break;
    case Status.CONFIRM :
    {
      textBox.style.visibility = 'visible';
      textBox.style.border = '7px solid green';
      textBox.style.backgroundColor = '#ceffa6';
      annulerButton.style.backgroundColor = '#cdffa6';
      textBoxText.innerHTML = 'Zone cible confirmée.';
      changeMapButton.style.visibility = 'hidden';
    }
    break;
    case Status.ON_TARGET_SITE :
    {
      textBox.style.visibility = 'visible';
      textBox.style.border = '7px solid green';
      textBox.style.backgroundColor = '#ceffa6';
      annulerButton.style.backgroundColor = '#cdffa6';
      textBoxText.innerHTML = 'Zone cible atteinte. Appuyer Pour Confirmer !';
      changeMapButton.style.visibility = 'hidden';
    }
    break;
    default: 
    {
      console.log("UNKNOW")
    }
  }
}

function zeroPad(nr,base){
        base = base || 10;
        var  len = (String(base).length - String(nr).length)+1;
        return len > 0 ? new Array(len).join('0')+nr : nr;
}

async function affInfoBox(isSelect)
{
  var M, L;
  if(isSelect == true)
  {
    const data = store.getters.getInfoSelected;
    old_nom = data.nom;
    old_calibre = data.calibre;
    old_occupation = data.occupation;
    if (old_nom == 'scierie' || old_nom == 'production0')
    {
      if (old_nom == 'scierie')
      {
        scierieBox.style.visibility = 'visible';
        M, L = scierieCalibreInfoSelect.options.length - 1;
        for (M = L; M>=0; M--)
        {
          scierieCalibreInfoSelect.remove(M);
        }
        calibrePossibles.forEach((calibre) => 
        {
          scierieCalibreInfoSelect.add(new Option(calibre, calibre));
        })
        scierieCalibreInfoSelect.add(new Option('', ''));
        if (old_calibre == undefined)
        {
          for (i, j = 0; j < scierieCalibreInfoSelect.options.length; j++)
          {
            i = scierieCalibreInfoSelect.options[j];
            if (i.value == '')
            {
              scierieCalibreInfoSelect.selectedIndex = j;
            } 
          }
        } else {
          for (i, j = 0; j < scierieCalibreInfoSelect.options.length; j++)
          {
            i = scierieCalibreInfoSelect.options[j];
            if (i.value == data.calibre)
            {
              scierieCalibreInfoSelect.selectedIndex = j;
            }
          }
        }
        
      } else {
        M, L = productionCalibreInfoSelect.options.length - 1;
        for (M = L; M>=0; M--)
        {
          productionCalibreInfoSelect.remove(M);
        }
        productionBox.style.visibility = 'visible';
        productionCalibreInfoSelect.add(new Option('', ''));
        productionCalibreInfoSelect.add(new Option('2000', '2000'));
        productionCalibreInfoSelect.add(new Option('2200', '2200'));
        if (old_calibre == undefined)
        {
          for (i, j = 0; j < productionCalibreInfoSelect.options.length; j++)
          {
            i = productionCalibreInfoSelect.options[j];
            if (i.value == '')
            {
              productionCalibreInfoSelect.selectedIndex = j;
            } 
          }
        } else {
          for (i, j = 0; j < productionCalibreInfoSelect.options.length; j++)
          {
            i = productionCalibreInfoSelect.options[j];
            if (i.value == data.calibre)
            {
              productionCalibreInfoSelect.selectedIndex = j;
            }
          }
        }
      }
    } else {
      infoBox.style.visibility = 'visible';
      titleInfoBox.innerHTML = data.nom;
      Occupation.innerHTML = 'Occupation : ' + old_occupation;
      M, L = calibreInfoSelect.options.length - 1;
      for (M = L; M>=0; M--)
      {
        calibreInfoSelect.remove(M);
      }
      calibrePossibles.forEach((calibre) => 
      {
        calibreInfoSelect.add(new Option(calibre, calibre));
      })
      calibreInfoSelect.add(new Option('', ''));
      if (data.calibre == undefined)
      {
        for (var i, j = 0; j < calibreInfoSelect.options.length; j++)
        {
          i = calibreInfoSelect.options[j];
          if (i.value == '')
          {
            calibreInfoSelect.selectedIndex = j;
          } 
        }
      } else {
        for (i, j = 0; j < calibreInfoSelect.options.length; j++)
        {
          i = calibreInfoSelect.options[j];
          if (i.value == data.calibre)
          {
            calibreInfoSelect.selectedIndex = j;
          }
        }
      }
    }
  } else {
    infoBox.style.visibility = 'hidden';
  }
}

function changeMap()
{
  if (changeMapButton.innerHTML === 'Voir Occupation')
  {
    changeMapButton.innerHTML = 'Voir Classique';
    drawOccupation();
    legende0.style.visibility = 'visible';
    legende25.style.visibility = 'visible';
    legende50.style.visibility = 'visible';
    legende75.style.visibility = 'visible';
    legende100.style.visibility = 'visible';
    changeScierieViewButton.style.visibility = 'hidden';
  } else {
    changeMapButton.innerHTML = 'Voir Occupation';
    redrawMap();
    legende0.style.visibility = 'hidden';
    legende25.style.visibility = 'hidden';
    legende50.style.visibility = 'hidden';
    legende75.style.visibility = 'hidden';
    legende100.style.visibility = 'hidden';
    changeScierieViewButton.style.visibility = 'visible';
  }
}

function changeScierie()
{
  if (changeScierieViewButton.innerHTML === 'Voir Compatible Scierie')
  {
    changeScierieViewButton.innerHTML = 'Cacher Compatible Scierie';
    changeMapButton.style.visibility = 'hidden';
    initOlder();
    drawScierieView();
  } else {
    changeScierieViewButton.innerHTML = 'Voir Compatible Scierie';
    changeMapButton.style.visibility = 'visible';
    redrawMap();
  }
}

</script>

<style scoped>

#textBox {
position: fixed;
top: 0;
left : 0;
border: 7px solid red;
width: 300px;
z-index: 401;
margin-top: 12px;
margin-left : 12px;
visibility: hidden;
padding: 10px;
font-weight: bold;
font-size: 25px;
border-radius: 20px;
}

#posInfos {
position: fixed;
top: 0;
right : 0;
z-index: 401;
visibility: visible;
color:#ffffff;
background-color: #000000;
font-size: 15px;
padding: 5px;
}

#infoBox, #scierieBox, #productionBox {
position: fixed;
right: 0;
top: 0;
border: 7px solid black;
width: 300px;
z-index: 401;
visibility: hidden;
margin-top: 15px;
margin-right: 15px;
padding: 3px;
font-weight: bold;
font-size: 15px;
border-radius: 20px;
background-color:aliceblue;
}

#validButton, #productionValidButton, #scierieValidButton, #annulerButton {
border: 4px solid black;
font-weight: bold;
font-size: 15px;
border-radius: 20px;
background-color:aliceblue;
margin: 10px;
padding: 10px;
color : black;
}

#calibreInfoSelect, #productionCalibreInfoSelect,  #scierieCalibreInfoSelect {
width: 225px;
height: 45px;
cursor: pointer;
background-color: white;
box-shadow: 0 2px 0 white;
border-radius: 2px;
font-size: 35px;
}

#btn-group {
width: 100%;
}

#btn-group button {
border : 1px solid black;
color : black;
cursor: pointer;
width: 20%;
font-size: 20px;
}

#btn0 {
background-color: #69B34C;
text-align: right;
}

#btn25 {
background-color: #ACB334;
text-align: right;
}

#btn50 {
background-color: #FFFF00;
text-align: right;
}

#btn75 {
background-color: #FF8E15;
text-align: right;
}

#btn100 {
background-color: #FF0D0D;
text-align: right;
}

#changeMapGroup {
position: fixed;
bottom: 25px;
left : 25px;
z-index: 401;
display: inline-block;
}

#changeScierieView {
position: fixed;
bottom: 25px;
right : 25px;
z-index: 401;
display: inline-block;
}

#changeMapButton {
background-color: #aaaaaa;
color : black;
font-weight: bold;
font-size: 30px;
bottom: 4px;
}

#changeScierieViewButton {
background-color: #aaaaaa;
color : black;
font-weight: bold;
font-size: 30px;
bottom: 4px;
}

#legende0 {
display: inline-block;
font-size: 30px;
background-color: #69B34C;
color : black;
font-weight: bold;
margin-left: 10px;
visibility: hidden;
}

#legende25 {
display: inline-block;
font-size: 30px;
background-color: #ACB334;
color : black;
font-weight: bold;
margin-left: 10px;
visibility: hidden;
}

#legende50 {
display: inline-block;
font-size: 30px;
background-color: #ffff00;
color : black;
font-weight: bold;
margin-left: 10px;
visibility: hidden;
}

#legende75 {
display: inline-block;
font-size: 30px;
background-color: #FF8E15;
color : black;
font-weight: bold;
margin-left: 10px;
visibility: hidden;
}

#legende100 {
display: inline-block;
font-size: 30px;
background-color: #FF0D0D;
color : black;
font-weight: bold;
margin-left: 10px;
visibility: hidden;
}

#legende100In {
display: inline-block;
font-size: 20px;
background-color: #FF0D0D;
color : black;
font-weight: bold;
margin-left: 10px;
}

#legende50In {
display: inline-block;
font-size: 20px;
background-color: #ffff00;
color : black;
font-weight: bold;
margin-left: 10px;
}

#legende0In {
  display: inline-block;
  font-size: 20px;
  background-color: #69B34C;
  color : black;
  font-weight: bold;
  margin-left: 10px;
}

</style>
<template>
    <div style="height: 100vh; width: 100vw;">
        <div id="mapid" ref="mapElement" style="height : 100%; width: 100%;"/>
    </div>
</template>

<script setup>

    import { onMounted } from 'vue';
    import 'leaflet/dist/leaflet.css';
    import L from 'leaflet';
    import '@/assets/leaflet-rotate-src';
    import { initMap, mapUpdate } from '@/utils/mapUtils';
    import { getJSON } from '@/api/functionsApi';
    import { startWebSocket } from '@/utils/wsUtils';

    var map;
    var ws;
    onMounted(() => {
        map = L.map('mapid', { rotate : true, bearing : 90, zoomControl : false, zoomSnap : 0.25, scrollWheelZoom: false, doubleClickZoom: false});
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap',
            maxZoom: 25,
            zIndex : 1,
            }).addTo(map);
        initMap(map);
        startWebSocket();
        getJSON('/api/getStockProperties').then((response) =>
        {
            mapUpdate(response);
            
        })

        setInterval(() => {
            getJSON('/api/getStockProperties').then((response) =>
            {
                mapUpdate(response);
                
            })
            if (ws === null)
            {
                startWebSocket();
            }
        }, 10000)
    })
    
</script>

<style scoped>

:root {
    --leaflet-tile-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
    .leaflet-tile {
        filter: var(--leaflet-tile-filter, none);
    }

    .leaflet-container {
        background: #303030;
    }
}

</style>
import { getJSON } from '@/api/functionsApi'
import store from '@/store';
import { Object } from 'core-js';
import L from 'leaflet';

const padding = -0.25;
const zoom = 3;

const mapStatus =
{
    CLEAN : 'CLEAN',
    PICK : 'PICK',
    OCCUPATION : 'OCCUPATION',
    SCIERIE_VIEW : 'SCIERIE_VIEW',
}

let listZone = [];

let map = {
    src : null,
    state : mapStatus.CLEAN,
    zones : {
        zone0 : [],
        scierie : [],
        sortieChaine : [],
        stock : [],
        production : [],
    },
    isZoomed : false,
    vehicle : null,
};

/****************** MAP MANAGEMENT ******************/

export function initMap(src)
{
    map.src = src;

    // TODO : clearZones

    drawGeoJSON(map.src, '/api/zone0', { weight : 2, color: '#f0ffff', opacity: 1 }, onZone0Click)
    .then((site) => { 
        map.zones.zone0 = site;
        map.src.fitBounds(map.zones.zone0[0].getBounds().pad(padding));
        fillListZones(site, 'zone0');
    });
    drawGeoJSON(map.src, '/api/scierie', { weight : 2, color: '#000080', opacity: 1 }, onDefaultClick)
        .then((site) => { 
            map.zones.scierie = site;
            fillListZones(site, 'scierie');
        });
    drawGeoJSON(map.src, '/api/stock', { weight : 2, color: '#3697ff', opacity: 1 }, onDefaultClick)
        .then((site) => { 
            map.zones.stock = site;
            fillListZones(site, 'stock'); 
        });
    drawGeoJSON(map.src, '/api/sortieChaine', { weight : 2, color: '#000000', opacity: 1 }, onDefaultClick)
        .then((site) => { 
            map.zones.sortieChaine = site;
            fillListZones(site, 'sortieChaine');
        });
    drawGeoJSON(map.src, '/api/production', { weight : 2, color : '#000080', opacity : 1}, onDefaultClick)
        .then((site) => { 
            map.zones.production = site; 
            fillListZones(site, 'production');
        })
    map.vehicle = L.marker([0, 0], { icon : L.icon ({ iconUrl : require('@/assets/red2.png'), iconSize : [25, 25]})}).addTo(map.src);  
}

export function cleanMap()
{
    if (map.state != mapStatus.CLEAN && (map.state != mapStatus.OCCUPATION && map.state != mapStatus.SCIERIE_VIEW))
    {
        clearZones([map.zones.stock, map.zones.scierie, map.zones.sortieChaine, map.zones.production]);
        drawGeoJSON(map.src, '/api/scierie', { weight : 2, color: '#000080', opacity: 1 }, onDefaultClick)
            .then((site) => { 
                map.zones.scierie = site; 
                fillListZones(site, 'scierie');
            });
        drawGeoJSON(map.src, '/api/stock', { weight : 2, color: '#3697ff', opacity: 1 }, onDefaultClick)
            .then((site) => { 
                map.zones.stock = site; 
                fillListZones(site, 'stock');
            });
        drawGeoJSON(map.src, '/api/sortieChaine', { weight : 2, color: '#000000', opacity: 1 }, onDefaultClick)
            .then((site) => { 
                map.zones.sortieChaine = site; 
                fillListZones(site, 'sortieChaine');
            });
        drawGeoJSON(map.src, '/api/production', { weight : 2, color : '#000080', opacity : 1}, onDefaultClick)
            .then((site) => { 
                map.zones.production = site; 
                fillListZones(site, 'production');
            })
        map.state = mapStatus.CLEAN;
    }
}

export function redrawMap()
{
    if (map.state == mapStatus.OCCUPATION || map.state == mapStatus.SCIERIE_VIEW)
    {
        clearZones([map.zones.stock, map.zones.scierie, map.zones.sortieChaine, map.zones.production]);
        drawGeoJSON(map.src, '/api/scierie', { weight : 2, color: '#000080', opacity: 1 }, onDefaultClick)
            .then((site) => { 
                map.zones.scierie = site; 
                fillListZones(site, 'scierie');
            });
        drawGeoJSON(map.src, '/api/stock', { weight : 2, color: '#3697ff', opacity: 1 }, onDefaultClick)
            .then((site) => { 
                map.zones.stock = site; 
                fillListZones(site, 'stock');
            });
        drawGeoJSON(map.src, '/api/sortieChaine', { weight : 2, color: '#000000', opacity: 1 }, onDefaultClick)
            .then((site) => { 
                map.zones.sortieChaine = site; 
                fillListZones(site, 'sortieChaine');
            });
        drawGeoJSON(map.src, '/api/production', { weight : 2, color : '#000080', opacity : 1}, onDefaultClick)
            .then((site) => { 
                map.zones.production = site; 
                fillListZones(site, 'production');
            })
        map.state = mapStatus.CLEAN;
    }
}


export function pickMap(calibrePick, secteurPick)
{
    if (map.state != mapStatus.PICK)
    {
        clearZones([map.zones.scierie, map.zones.stock]);
        drawGeoJSONHighlight('/api/scierie', { weight : 2, color : '#000080', opacity : 1}, onDefaultClick, { weight : 2, color : '#3A9D23', opacity : 1}, onDefaultClick, calibrePick, secteurPick)
            .then((site) => {
                map.zones.scierie = site;
                fillListZones(site, 'scierie');
            });
        drawGeoJSONHighlight('/api/stock', { weight : 2, color : '#3697ff', opacity : 1}, onDefaultClick, { weight : 2, color : '#3A9D23', opacity : 1}, onDefaultClick, calibrePick, secteurPick)
            .then((site) => {
                map.zones.stock = site;
                fillListZones(site, 'stock');
            });
        map.state = mapStatus.PICK;
    }
}

function clearZones(zones)
{
    let spot = '';
    zones.forEach((zone) =>
    {
        if (zone == map.zones.scierie)
        {
            spot = 'scierie'
        } else if (zone == map.zones.production)
        {
            spot = 'production'
        } else if (zone == map.zones.sortieChaine)
        {
            spot = 'sortieChaine'
        } else if (zone == map.zones.stock)
        {
            spot = 'stock'
        } else if (zone == map.zones.zone0)
        {
            spot = 'zone0'
        }

        zone.forEach((site) => map.src.removeLayer(site));
        delSpotInListZones(spot);
        zone = [];
    })
}

export function moveVehicle(lat, lon)
{
    map.vehicle.setLatLng(new L.LatLng(lat, lon));
}


export function changeVehicleIcon(type)
{
    if (type == 'RTK')
    {
        map.vehicle.setIcon(L.icon ({ iconUrl : require('@/assets/green2.png'), iconSize : [25, 25]}));
    } else {
        map.vehicle.setIcon(L.icon ({ iconUrl : require('@/assets/red2.png'), iconSize : [25, 25]}));
    }
}

/****************** DRAW ZONE FUNCTIONS ******************/

async function drawGeoJSONHighlight(source, nonSelectStyle, nonSelectFeature, selectStyle, selectFeature, calibrePick, secteurPick)
{
    const zoneArray = await getJSON(source);
    var listZoneHighlith = [];
    let zoneGeo;

    zoneArray.features.forEach(zone =>
    {
        if (zone.properties.calibre == calibrePick && (zone.properties.secteur == 'ALL' || zone.properties.secteur ==secteurPick))
        {
            zoneGeo = new L.geoJson(zone, {style : selectStyle, onEachFeature : selectFeature}).addTo(map.src);
            listZoneHighlith.push(zoneGeo);
        } else {
            zoneGeo = new L.geoJson(zone, {style : nonSelectStyle, onEachFeature : nonSelectFeature}).addTo(map.src);
            listZoneHighlith.push(zoneGeo);
        }
    })
    return listZoneHighlith;
}

/****************** ON CLICK FUNCTIONS ******************/

function onDefaultClick(feature, layer)
{
    layer.on('click', function(event)
    {
        if (map.isZoomed === false)
        {
            map.src.setView(event.latlng, map.src.getZoom() + zoom);
            map.isZoomed = true;
        } else {
            const payload = { 'nom' : feature.properties.nom, 'calibre' : feature.properties.calibre, 'occupation' : feature.properties.occupation };
            store.commit('SET_INFO_SELECTED', payload);
        }
    })
}

function onZone0Click(feature, layer)
{
    layer.on('click', function(event)
    {
        if (map.isZoomed === false)
        {
            map.src.setView(event.latlng, map.src.getZoom() + zoom);
            map.isZoomed = true;
        } else {
            map.src.fitBounds(map.zones.zone0[0].getBounds().pad(padding));
            map.isZoomed = false;
        }
    })
}

/****************** EXPORT FUNCTION ******************/

export function mapUpdate(updates)
{ 
    Object.keys(map.src._layers).forEach((key) => 
    {
        if ('feature' in map.src._layers[key])
        {
            const result = updates.find(x => x.nom === map.src._layers[key].feature.properties.nom);
            if (result.nom === 'production0')
            {
                if (result.longueur_mm != map.src._layers[key].feature.properties.calibre)
                {
                    map.src._layers[key].feature.properties.calibre = result.longueur_mm;
                    let index = listZone.findIndex(w => w.nom == result.nom);
                    if (index != -1)
                    {
                        listZone[index].calibre = result.longueur_mm;
                    }
                }
            } else {
                if (result.calibre != map.src._layers[key].feature.properties.calibre)
                {
                    map.src._layers[key].feature.properties.calibre = result.calibre;
                   
                    let index = listZone.findIndex(w => w.nom == result.nom);
                    if (index != -1)
                    {
                        listZone[index].calibre = result.calibre;
                    }
                }
            }
            if (result.occupation != map.src._layers[key].feature.properties.occupation)
            {
                map.src._layers[key].feature.properties.occupation = result.occupation;
                let index = listZone.findIndex(w => w.nom == result.nom);
                if (index != -1)
                {
                    listZone[index].occupation = result.occupation;
                }
            }
            if (result.tLast != map.src._layers[key].feature.properties.tLast)
            {
                map.src._layers[key].feature.properties.tLast = result.tLast;
                let index = listZone.findIndex(w => w.nom == result.nom)
                if (index != -1)
                {
                    listZone[index].tLast = result.tLast;
                }
            }
        }
    })
}


export function getInfoOf(bin)
{
    let result = listZone.find(x => x.nom == bin);
    return result;
}
/****************** OLD FUNCTIONS ******************/

let OlderName = '';

export function initOlder()
{
    let dateSave = null;
    let scierieCalibre = getInfoOf('scierie');
    let result = listZone.filter(w => w.calibre == scierieCalibre.calibre);
    for (let el of result)
    {
        if (el.spot == "stock")
        {
            console.log(el);
            if (dateSave == null || Date.parse(el.tLast) < dateSave)
            {
                dateSave = Date.parse(el.tLast);
                OlderName = el.nom;
            }
        }
    }
    console.log(OlderName);
    console.log(dateSave);
}

export async function drawGeoJSON(map, source, style, onEachFeature)
{
    const zoneArray = await getJSON(source);
    var listZone = []

    zoneArray.features.forEach(zone =>
    {
        var zoneGeoJSON = new L.geoJson(zone, { style : style, onEachFeature : onEachFeature }).addTo(map);
        listZone.push(zoneGeoJSON);
    })
    return listZone;
}

function fillListZones(zones, spot)
{

    for (const zone of zones)
    {
        let listKey = Object.keys(zone._layers);
        for (const key of listKey)
        {
            let zoneData = { nom : zone._layers[key].feature.properties.nom, spot : spot, calibre : zone._layers[key].feature.properties.calibre, secteur : zone._layers[key].feature.properties.secteur, tLast : zone._layers[key].feature.properties.tLast, occupation : zone._layers[key].feature.properties.occupation };
            listZone.push(zoneData);
        }
    }
}

function delSpotInListZones(spot)
{
    let index = listZone.findIndex(x => x.spot == spot);
    while (index > 0)
    {
        listZone.splice(index, 1);
        index = listZone.findIndex(x => x.spot == spot);
    }
}


export function drawOccupation()
{
    if (map.state != mapStatus.OCCUPATION)
    {
        clearZones([map.zones.scierie, map.zones.stock, map.zones.sortieChaine, map.zones.production]);
        drawGeoJSONOccupation(map.src, '/api/scierie', onDefaultClick)
            .then((site) => { 
                map.zones.scierie = site;
                fillListZones(site, 'scierie');
            });
        drawGeoJSONOccupation(map.src, '/api/stock', onDefaultClick)
            .then((site) => { 
                map.zones.stock = site;
                fillListZones(site, 'stock');
            });
        drawGeoJSONOccupation(map.src, '/api/sortieChaine', onDefaultClick)
            .then((site) => { 
                map.zones.sortieChaine = site;
                fillListZones(site, 'sortieChaine');
            });
        drawGeoJSONOccupation(map.src, '/api/production', onDefaultClick)
            .then((site) => { 
                map.zones.production = site;
                fillListZones(site, 'production');
            });
        map.state = mapStatus.OCCUPATION;
    } 
}

export function drawScierieView()
{
    if (map.state != mapStatus.SCIERIE_VIEW)
    {
        let scierieCalibre = getInfoOf('scierie');
        clearZones([map.zones.scierie, map.zones.stock, map.zones.sortieChaine]);
        drawGeoJSONScierieView(map.src, '/api/scierie', onDefaultClick, scierieCalibre.calibre)
            .then((site) => {
                map.zones.scierie = site;
                fillListZones(site, 'scierie');
            });
        drawGeoJSONScierieView(map.src, '/api/stock', onDefaultClick, scierieCalibre.calibre)
            .then((site) => {
                map.zones.stock = site;
                fillListZones(site, 'stock');
            });
        drawGeoJSONScierieView(map.src, '/api/sortieChaine', onDefaultClick, scierieCalibre.calibre)
            .then((site) => {
                map.zones.sortieChaine = site;
                fillListZones(site, 'sortieChaine');
            });
        
        map.state = mapStatus.SCIERIE_VIEW;        
    }
}

async function drawGeoJSONScierieView(map, source, onEachFeature, calibre)
{
    const zoneArray = await getJSON(source);
    var listZone = [];

    zoneArray.features.forEach(zone =>
    {
        let calibreZone = zone.properties.calibre;
        var zoneGeoJSON;

        if (calibreZone != calibre)
        {
            zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#3697ff', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
            listZone.push(zoneGeoJSON);
        } else {
            if (zone.properties.nom == OlderName)
            {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#f01a25', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            } else {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#ff57e9', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            }    
        }
    })
    return listZone;
}

async function drawGeoJSONOccupation(map, source, onEachFeature)
{
    const zoneArray = await getJSON(source);
    var listZone = [];

    zoneArray.features.forEach(zone =>
    {
        var occupation = zone.properties.occupation;
        var zoneGeoJSON;

        if (occupation === undefined || occupation === null)
        {
            zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#000080', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
            listZone.push(zoneGeoJSON);
        } else {
            if (occupation == 0)
            {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#69B34C', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            } else if (occupation <= 25)
            {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#ACB334', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            } else if (occupation <= 50)
            {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#FFFF00', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            } else if (occupation <= 75)
            {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#FF8E15', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            } else {
                zoneGeoJSON = new L.geoJson(zone, { style : { weight : 2, color: '#FF0D0D', opacity: 1 }, onEachFeature : onEachFeature }).addTo(map);
                listZone.push(zoneGeoJSON);
            }
        }
    })
    return listZone;
}
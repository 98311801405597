import { createStore } from 'vuex';
import { notify } from '@kyvg/vue3-notification';
import { resetState } from '@/utils/stateUtils';
const axios = require("axios");
const config = require ("../config");

export default createStore({
    state: {
        accessToken : '',
        isLog : false,
        zoomLevel : 0,
        selectTarget : '',
        infoSelected : { nom : '', calibre : '', occupation : '' },
        infoIsSelected : false,
        affPos : { visibility : 'hidden', text : '', color : '', border : '', buttonVisibility : '', inVisibility : ''  },
        affPosChange : false,
        posInfos : { x : '', y : '', pos : '', button : '', code : 0},
        posInfosChange : false,
    },
    getters: {
        getAccessToken : (state) => state.accessToken,
        getIsLog : (state) => state.isLog, 
        getZoomLevel : (state) => state.zoomLevel,
        getSelectTarget : (state) => state.selectTarget,
        getInfoSelected : (state) => state.infoSelected, 
        getInfoIsSelected : (state) => state.infoIsSelected,
        getAffPosChange : (state) => state.affPosChange,
        getAffPos : (state) => state.affPos,
        getPosInfos : (state) => state.posInfos,
        getPosInfosChange : (state) => state.posInfosChange,
    },
    actions: {
      async tryToLog({ commit }, {username, password }) {
        const data = { identifiant : username, password : password };
        axios
        .post(`${config.apiUrl}/api/login/`, data)
        .then((response) => {
            if (response.status == 200) {
                commit('SET_ACCESS_TOKEN', response.data.access_token);
                commit('SET_IS_LOG', true);
                resetState();
            }
        })
        .catch ((error) => {
            notify({title : 'Erreur', text : error, type : 'error'})
            console.log(error);
        });
      },
    },
    mutations: {
        SET_ACCESS_TOKEN(state, access_token) {
            state.accessToken = access_token;
        },
        SET_IS_LOG(state, isLog) {
            state.isLog = isLog;
        },
        SET_ZOOM_LEVEL(state, zoomLevel) {
          state.zoomLevel = zoomLevel;
        },
        SET_SELECT_TARGET(state, target) {
            state.selectTarget = target;
        },
        SET_INFO_SELECTED(state, data) {
            state.infoSelected.nom = data.nom;
            state.infoSelected.calibre = data.calibre;
            state.infoSelected.occupation = data.occupation;
            state.infoIsSelected = (data.nom != '');
        },
        SET_AFF_POS(state, data)
        {
            state.affPos.visibility = data.visibility;
            state.affPos.color = data.color;
            state.affPos.text = data.text;
            state.affPos.buttonVisibility = data.buttonVisibility;
            state.affPos.border = data.border;
            state.affPos.inVisibility = data.inVisibility;
        },
        SET_AFF_POS_CHANGE(state, data)
        {
            state.affPosChange = data;
        },
        SET_POS_INFOS(state, data)
        {
            state.posInfos.x = data.x;
            state.posInfos.y = data.y;
            state.posInfos.pos = data.pos;
            state.posInfos.button = data.button;
            state.posInfos.code = data.code;
            state.posInfosChange = !state.posInfosChange;
        }
    }
})

//TODO : replace every AFF_POS with an object
<template>
  <div class="main-container">
    <div v-if=store.state.isLog>
      <MapView/>
    </div>
    <div v-else>
      <LoginView/>
    </div>
  </div> 
  <notifications position="center top"/>
</template>

<script setup>
  import LoginView from "./views/LoginView.vue";
  import MapView from "./views/MapView.vue";
  import { useStore } from "vuex";

  const store = useStore();
</script>

<style>
html, body {
  background-color: aquamarine;
  align-items: center;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-container {
  background-color: aquamarine;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

</style>

import { moveVehicle, cleanMap, pickMap, changeVehicleIcon, getInfoOf } from './mapUtils';
import { postJSON } from '@/api/functionsApi';
import store from '@/store';
import { errorWebSocket } from './wsUtils';
import { notify } from '@kyvg/vue3-notification';
export const posStatus = {
    NONE : 'NONE',
    ON_BIN : 'ON_BIN'
}

export const pickStatus = {
    NONE : 'NONE',
    PICK : 'PICK'
}

let appState = 
{
    posState : posStatus.NONE,
    pos : '',
    pickState : pickStatus.NONE,
    pick : '',
    pickSecteur : '',
    historyPick : '',
}

export function onMessageReceive(incomingData)
{
    moveVehicle(incomingData.vehiclePos.lat, incomingData.vehiclePos.lon);
    changeVehicleIcon(incomingData.fixType);

    if (incomingData.actualBin != '' && incomingData.actualBin != '?')
    {
        appState.posState = posStatus.ON_BIN;
        appState.pos = incomingData.actualBin;
    } else {
        appState.posState = posStatus.NONE;
        appState.pos = '';
    }

    let binData;

    switch (appState.pickState) {
        case pickStatus.NONE:
            if (incomingData.confirmBin == appState.pos && incomingData.confirmBin != appState.historyPick)
            {
                appState.pickState = pickStatus.PICK;
                binData = getInfoOf(incomingData.confirmBin);
                appState.pick = binData.calibre;
                appState.pickSecteur = binData.secteur;
                appState.historyPick = incomingData.confirmBin;
                const params = {'calibre' : appState.pick, 'zone' : incomingData.confirmBin, 'lon' : incomingData.vehiclePos.lon, 'lat' : incomingData.vehiclePos.lat, 'alt' : incomingData.vehiclePos.alt, 'speed' : incomingData.vehiclePos.speed};
                postJSON('/api/take', params);
            } else {
                binData = getInfoOf(incomingData.actualBin);
                if (binData != undefined)
                {
                    appState.pick =  binData.calibre;
                }           
            }
            break;
        case pickStatus.PICK:
            if (incomingData.confirmBin == appState.pos && incomingData.confirmBin != appState.historyPick)
            {
                const params = {'calibre' : appState.pick, 'zone' : incomingData.confirmBin, 'lon' : incomingData.vehiclePos.lon, 'lat' : incomingData.vehiclePos.lat, 'alt' : incomingData.vehiclePos.alt, 'speed' : incomingData.vehiclePos.speed};
                postJSON('/api/release', params);
                appState.pickState = pickStatus.NONE;
                appState.historyPick = appState.pos;
                appState.pick = '';  
            }
            break; 
        default:
            break;
    }

    if (appState.posState == posStatus.NONE)
    {
        if (appState.pickState == pickStatus.NONE)
        {
            cleanMap();
            updateMapView('hidden', '', '', '', 'visible', 'none');
        } else {
            pickMap(appState.pick, appState.pickSecteur);
            updateMapView('visible', appState.pick + ' : En transit','#ff9457', '7px solid orange', 'hidden', 'none');
        }
    } else {
        if (appState.pickState == pickStatus.NONE)
        {
            cleanMap();
            if (appState.historyPick == appState.pos)
            {
                updateMapView('hidden', '', '', '', 'visible', 'none');
            } else {
                binData = getInfoOf(incomingData.actualBin);
                console.log(binData);
                updateMapView('visible', appState.pick + ' [' + binData.occupation + '%] ' + ' : Saisie. À confirmer.', '#ff6767', '7px solid red', 'visible', 'block');
            }
        } else {
            pickMap(appState.pick, appState.pickSecteur);
            if (appState.historyPick == appState.pos)
            {
                updateMapView('visible', appState.pick + ' : Saisie','#ff9457', '7px solid orange', 'hidden', 'block');
            } else {
                binData = getInfoOf(incomingData.actualBin);
                if (appState.pick == binData.calibre)
                {
                    updateMapView('visible', appState.pick + ' [' + binData.occupation + '%]  ' + ' : Zone Valide. À confirmer.', '#ceffa6', '7px solid green', 'hidden', 'block');
                } else {
                    updateMapView('visible', appState.pick + ' [' + binData.occupation + '%] ' + ' : Zone Invalide. À confirmer', '#ffff93', '7px solid yellow', 'hidden', 'block');
                }
            }
        }
    }
}

function updateMapView(visibility, text, color, border, buttonVisibility, inVisibility)
{
    const lastData = store.getters.getAffPos;
    const lastVisibility = lastData.visibility;
    const lastColor = lastData.color;
    const lastBorder = lastData.border;
    const lastText = lastData.text;
    const lastButtonVisibility = lastData.buttonVisibility;
    const lastInVisibility = lastData.inVisibility;
    const status = store.getters.getAffPosChange;


    if (lastBorder != border || lastText != text || lastVisibility != visibility || lastColor != color || lastButtonVisibility != buttonVisibility || lastInVisibility != inVisibility)
    {
        const payload = { 'visibility' : visibility, 'text' : text, 'color' : color, 'border' : border, 'buttonVisibility' : buttonVisibility, 'inVisibility' : inVisibility};
        store.commit('SET_AFF_POS', payload);

        store.commit('SET_AFF_POS_CHANGE', !status);
    }

}

export function resetState()
{
    console.log('IN RESET STATE');
    appState.posState = posStatus.NONE;
    appState.pos = '';
    appState.pickState = pickStatus.NONE;
    appState.pick = '';
    appState.pickSecteur = '';
    appState.historyPick = '';
    errorWebSocket();
    updateMapView('hidden', '', '', '', 'visible', 'hidden');
    console.log('RESET STATE OVER');
}

export function getStateCode()
{
    let code = 0;
    if (appState.posState == posStatus.ON_BIN)
    {
        code = code + 100;
    }
    if (appState.pickState == pickStatus.PICK)
    {
        code = code + 10;
    }
    if (appState.historyPick == appState.pick)
    {
        code = code + 1;
    }
    return code;
}

export function changeDataForActualBin(value)
{
    const data = getInfoOf(appState.pos);
    const params = {'occupation' : parseInt(value), 'calibre' : data.calibre, 'zone' : appState.pos}
    console.log('TRY UPDATE STOCK WITH THIS PARAMS');
    console.log(params);
    notify({title : 'INFO', text : 'Change ' + params.zone + ' occupation to ' + value, type : 'warn'})
    postJSON('/api/updateStock', params);
}
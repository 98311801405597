import { getStateCode, onMessageReceive } from './stateUtils';
import { resetWatchdog } from './watchdog';
import store from '@/store';

let ws;

let incomingData = 
{
    vehiclePos : {
        lon : 0,
        lat : 0,
        alt : 0,
        speed : 0,
    },
    actualBin : '',
    confirmBin : '',
    fixType : '',
}

export function startWebSocket()
{
    ws = new WebSocket('ws://localhost:8887');
    ws.onmessage = webSocketMessage;
    ws.onclose = closeWebSocket;
    ws.onerror = errorWebSocket;
}

function webSocketMessage(event)
{
    resetWatchdog();
    let code = getStateCode();
    const response = JSON.parse(event.data);
    incomingData.vehiclePos.lon = response.lon;
    incomingData.vehiclePos.lat = response.lat;
    incomingData.vehiclePos.alt = response.alt;
    incomingData.vehiclePos.speed = response.speed;
    incomingData.actualBin = response.rawbin;
    incomingData.confirmBin = response.confirmBin;
    incomingData.fixType = response.fixType;
    const payload = {'x' : response.lon, 'y' : response.lat, 'pos' : response.rawbin, 'button' : 'ui', 'code' : code };
    store.commit('SET_POS_INFOS', payload);
    onMessageReceive(incomingData);
}

export function errorWebSocket()
{
    if (ws != null)
    {
        ws.close();
    }
}

function closeWebSocket()
{
    ws = null;
    setTimeout(startWebSocket, 1000);
}
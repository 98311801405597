import store from '../store';

const axios = require("axios");
const config = require("../config");

export async function getJSON(url_)
{
    const url = `${config.apiUrl}` + url_;
    const accessToken = store.getters.getAccessToken;
    let data;

    await axios
        .get(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${accessToken}`,
            }
        }).then((response) => {
            data = response.data;
        }).catch((error) => {
            console.log(error);
        });
    return data;
}

export async function postJSON(url_, parameters)
{
    const url = `${config.apiUrl}` + url_;
    const accessToken = store.getters.getAccessToken;
    await axios.post(url, parameters, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
        }
    })
}